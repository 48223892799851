import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-aviso-respuesta-ko',
  templateUrl: './aviso-respuesta-ko.component.html',
  styleUrls: ['./aviso-respuesta-ko.component.css'],
})
export class AvisoRespuestaKoComponent {
  constructor(
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.translationService.translate();
  }

  ngAfterViewInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
  }
}
