import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  lang: string = '';

  constructor(private translateService: TranslateService) {}

  detectLanguage(): void {
    this.lang = new URL(window.location.href).searchParams.get('lang') || 'es';
  }

  translate(): void {
    this.translateService.use(this.lang);
  }

  getLang(): string {
    return this.lang;
  }
}
