import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SolicitudFacturaRespuesta } from '../interfaces/solicitud-factura-response.interface';


@Injectable({
  providedIn: 'root'
})
export class SolicitarFacturaService {

  baseUrl: string;

  constructor(private http:HttpClient) {
    this.baseUrl =`${environment.apiUrl}/v1/invoicing`;
   }

  postInvoicing(data: any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<SolicitudFacturaRespuesta>(`${this.baseUrl}/email`, data, httpOptions);

  }


}
