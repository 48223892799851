import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Facturacion } from '../interfaces/facturacion.interface';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FacturacionService {

  baseUrl: string;

  constructor(private http:HttpClient) {
    this.baseUrl =`${environment.apiUrl}/v1/invoicing`;
   }


    getInvoicing(country: String, formRechargeDate: String, formAmount: Number, formLastDigits: String){
      return firstValueFrom(
        this.http.get<Facturacion>(`${this.baseUrl}/receipt?country=${country}&recharge_date=${formRechargeDate}&amount=${formAmount}&cc_last_4_digits=${formLastDigits}`)
      )
    }


}
