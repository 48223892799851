<div class="invoicingForm">
  <div class="container my-5">
    <a class="buttonText" [routerLink]="['/']"> &larr; {{"responseKo.buttonBack" | translate}}</a>
    <div>
      <div class="py-5 my-5">
        <div class="row justify-content-center">
          <div class="col">
            <div class="text-center">
              <img src="../../../../assets/img/care.png" />
            </div>
            <div class="greySubtitle mt-3 px-5">
              {{"responseKo.text1" | translate}}
            </div>
            <div class="row justify-content-center">
              <div class="col-7 subtitle text-center">
                {{"responseKo.text2" | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
