import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SolicitarFacturaService } from 'src/app/services/solicitar-factura.service';
import { ActivatedRoute } from '@angular/router';
import { SolicitudFacturaRespuesta } from 'src/app/interfaces/solicitud-factura-response.interface';
import * as data from 'src/assets/json/provider.json';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-solicitar-factura',
  templateUrl: './solicitar-factura.component.html',
  styleUrls: ['./solicitar-factura.component.css'],
})
export class SolicitarFacturaComponent {
  invoicingForm: FormGroup;
  sessionId: string = '';
  ticketNumber: string = '';
  taxIdentificationNumber: string = '';
  showMessage: boolean = false;
  jsonData: any = data;
  privacyUrl: string = '';
  constructor(
    private translationService: TranslationService,
    private solicitarFactura: SolicitarFacturaService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.updateUrlLink();

    this.invoicingForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(70),
      ]),
      cifNif: new FormControl('', [
        Validators.required,
        Validators.maxLength(16),
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.maxLength(16),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/),
        Validators.maxLength(56),
      ]),
      intracommunityVATNumber: new FormControl('', []),
      address: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
      ]),
      postalCode: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
      ]),
      locality: new FormControl('', [
        Validators.required,
        Validators.maxLength(35),
      ]),
      country: new FormControl('', [Validators.required]),
      checkConsent: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.ticketNumber =
      this.route.snapshot.queryParamMap.get('ticketNumber') || '';
    this.sessionId = this.route.snapshot.queryParamMap.get('sessionId') || '';
    this.taxIdentificationNumber =
      this.route.snapshot.queryParamMap.get('taxIdentificationNumber') || '';
    this.translationService.translate();
  }

  ngAfterViewInit() {
    window.scroll({ top: 0, left: 0, behavior: 'instant' });
  }

  checkError(field: string, error: string): boolean | undefined {
    return (
      this.invoicingForm.get(field)?.hasError(error) &&
      this.invoicingForm.get(field)?.touched
    );
  }

  onSubmit() {
    if (this.invoicingForm.valid) {
      const data = {
        receipt_number: this.ticketNumber,
        receipt_transaction_id: this.sessionId,
        customer_society_number: this.invoicingForm.value.cifNif,
        customer_tax_identification_number: this.taxIdentificationNumber,
        customer_community_taxpayer_id:
          this.invoicingForm.value.intracommunityVATNumber,
        customer_fullname_company_name: this.invoicingForm.value.name,
        customer_telephone_number: this.invoicingForm.value.phoneNumber,
        customer_email_address: this.invoicingForm.value.email,
        address_street_name: this.invoicingForm.value.address,
        address_locality: this.invoicingForm.value.locality,
        address_zipcode: this.invoicingForm.value.postalCode,
        address_country: this.invoicingForm.value.country,
      };
      this.solicitarFactura.postInvoicing(data).subscribe(
        (response: SolicitudFacturaRespuesta) => {
          if (response.result == 'ok') {
            this.router.navigate(['/solicitar-factura/respuesta']);
          } else {
            this.router.navigate(['/solicitar-factura/respuestaKo']);
          }
        },
        () => {
          this.router.navigate(['/solicitar-factura/respuestaKo']);
        }
      );
    } else {
      this.showMessage = true;
    }
  }

  updateUrlLink() {
    const baseUrl = environment.baseUrl;
    const currentLanguage = this.translateService.currentLang;
    if (currentLanguage === 'en') {
      this.privacyUrl = `${baseUrl}/en/privacy-policy`;
    } else if (currentLanguage === 'pt') {
      this.privacyUrl = `${baseUrl}/pt/politica-privacidade`;
    } else {
      this.privacyUrl = `${baseUrl}/politica-privacidad`;
    }
  }
}
