<div class="invoicingForm">
  <div class="container my-5">
    <form [formGroup]="invoicingForm" (ngSubmit)="onSubmit()">
      <a class="buttonText" [routerLink]="['/']">
        &larr; {{ "invoicingForm.buttonBack" | translate }}</a
      >
      <div class="row">
        <div class="col-12">
          <h1 class="greenTitle">
            {{ "invoicingForm.titleGreen" | translate }}
            <span class="blueTitle">{{
              "invoicingForm.titleBlue" | translate
            }}</span>
          </h1>
          <p class="subtitle">
            {{ "invoicingForm.subtitle" | translate }}
          </p>
        </div>
        <div class="col col-lg-8 col-xxl-6">
          <div class="disclaimer mt-3">
            {{ "invoicingForm.disclaimer" | translate }}
          </div>
        </div>
      </div>
      <div class="subTitleForm mt-5 mb-3">
        {{ "invoicingForm.form.title" | translate }}
      </div>
      <p class="textInfo">
        <span class="asterisk">*</span>
        {{ "invoicingForm.textInfo" | translate }}
      </p>
      <div class="row">
        <div class="col-12 col-sm">
          <label for="name" class="labelText"
            ><span class="asterisk">*</span>
            {{ "invoicingForm.form.company" | translate }}</label
          >
          <input
            type="text"
            name="name"
            id="name"
            class="form-control"
            formControlName="name"
          />
          <div *ngIf="checkError('name', 'required')" class="infoError">
            {{ "invoicingForm.form.required" | translate }}
          </div>
          <div *ngIf="checkError('name', 'maxlength')" class="infoError">
            {{ "invoicingForm.form.max70" | translate }}
          </div>
        </div>
        <div class="col-12 col-sm mt-3 mt-sm-0">
          <label for="cifNif" class="labelText"
            ><span class="asterisk">*</span>
            {{ "invoicingForm.form.cifNif" | translate }}</label
          >
          <input
            type="text"
            name="cifNif"
            id="cifNif"
            class="form-control"
            formControlName="cifNif"
          />
          <div *ngIf="checkError('cifNif', 'required')" class="infoError">
            {{ "invoicingForm.form.required" | translate }}
          </div>
          <div *ngIf="checkError('cifNif', 'maxlength')" class="infoError">
            {{ "invoicingForm.form.max16" | translate }}
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm">
          <label for="phoneNumber" class="labelText"
            ><span class="asterisk">*</span>
            {{ "invoicingForm.form.phone" | translate }}</label
          >
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            class="form-control"
            formControlName="phoneNumber"
          />
          <div *ngIf="checkError('phoneNumber', 'required')" class="infoError">
            {{ "invoicingForm.form.required" | translate }}
          </div>
          <div *ngIf="checkError('phoneNumber', 'maxlength')" class="infoError">
            Introduce un máximo de 16 caracteres.
          </div>
        </div>
        <div class="col-12 col-sm mt-3 mt-sm-0">
          <label for="email" class="labelText"
            ><span class="asterisk">*</span>
            {{ "invoicingForm.form.email" | translate }}</label
          >
          <input
            type="text"
            name="email"
            id="email"
            class="form-control"
            formControlName="email"
          />
          <div *ngIf="checkError('email', 'required')" class="infoError">
            {{ "invoicingForm.form.required" | translate }}
          </div>
          <div *ngIf="checkError('email', 'maxlength')" class="infoError">
            {{ "invoicingForm.form.max16" | translate }}
          </div>
          <div *ngIf="checkError('email', 'pattern')" class="infoError">
            {{ "invoicingForm.form.validEmail" | translate }}
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label for="intracommunityVATNumber" class="labelText">{{
            "invoicingForm.form.intracomunityVAT" | translate
          }}</label>
          <input
            type="text"
            name="intracommunityVATNumber"
            id="intracommunityVATNumber"
            class="form-control"
            formControlName="intracommunityVATNumber"
          />
        </div>
      </div>
      <div class="subTitleForm mt-5 mb-3">
        {{ "invoicingForm.form.title_2" | translate }}
      </div>
      <div class="row mt-3">
        <div class="col">
          <label for="address" class="labelText"
            ><span class="asterisk">*</span>
            {{ "invoicingForm.form.address" | translate }}</label
          >
          <input
            type="text"
            name="address"
            id="address"
            class="form-control"
            formControlName="address"
          />
          <div *ngIf="checkError('address', 'required')" class="infoError">
            {{ "invoicingForm.form.required" | translate }}
          </div>
          <div *ngIf="checkError('address', 'maxlength')" class="infoError">
            {{ "invoicingForm.form.max35" | translate }}
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-4">
          <label for="postalCode" class="labelText"
            ><span class="asterisk">*</span>
            {{ "invoicingForm.form.postCode" | translate }}</label
          >
          <input
            type="text"
            name="postalCode"
            id="postalCode"
            class="form-control"
            formControlName="postalCode"
          />
          <div *ngIf="checkError('postalCode', 'required')" class="infoError">
            {{ "invoicingForm.form.required" | translate }}
          </div>
          <div *ngIf="checkError('postalCode', 'maxlength')" class="infoError">
            {{ "invoicingForm.form.max10" | translate }}
          </div>
        </div>
        <div class="col-12 col-sm mt-3 mt-sm-0">
          <label for="locality" class="labelText"
            ><span class="asterisk">*</span>
            {{ "invoicingForm.form.locality" | translate }}</label
          >
          <input
            type="text"
            name="locality"
            id="locality"
            class="form-control"
            formControlName="locality"
          />
          <div *ngIf="checkError('locality', 'required')" class="infoError">
            {{ "invoicingForm.form.required" | translate }}
          </div>
          <div *ngIf="checkError('locality', 'maxlength')" class="infoError">
            {{ "invoicingForm.form.max35" | translate }}
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <label for="country" class="labelText"
            ><span class="asterisk">*</span>
            {{ "invoicingForm.form.country" | translate }}</label
          >
          <select
            name="country"
            id="country"
            class="form-select"
            formControlName="country"
          >
            <option value="" disabled>
              {{ "invoicingForm.form.select" | translate }}
            </option>
            <option value="ES">
              {{ "invoicingForm.form.selectCountry" | translate }}
            </option>
          </select>
          <div *ngIf="checkError('country', 'required')" class="infoError">
            {{ "invoicingForm.form.required1" | translate }}
          </div>
        </div>
      </div>
      <div class="form-check mt-3">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="checkConsent"
          formControlName="checkConsent"
        />
        <label class="form-check-label consentimientoText" for="checkConsent">
          {{ "invoicingForm.form.consent" | translate }}
          <a href="{{ privacyUrl }}">{{
            "invoicingForm.form.linkPrivacy" | translate
          }}</a>
        </label>
      </div>
      <div *ngIf="checkError('checkConsent', 'required')" class="infoError">
        {{ "invoicingForm.form.required2" | translate }}
      </div>
      <div class="error-message" *ngIf="showMessage">
        {{ "invoicingForm.form.required3" | translate }}
      </div>
      <div class="my-5 text-start">
        <button type="submit" class="greenButton btnFullW">
          {{ "invoicingForm.form.buttonSend" | translate }} &rarr;
        </button>
      </div>
    </form>
  </div>
</div>
<div class="img-solicitar"></div>
<div class="text-img">
  <div class="greenTitle">
    {{ "invoicingForm.form.textEndGreen" | translate }}
    <div class="blueTitle">
      {{ "invoicingForm.form.textEndBlue" | translate }}
    </div>
  </div>
</div>
