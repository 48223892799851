import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FacturacionComponent } from './components/facturacion/facturacion.component';
import { SolicitarFacturaComponent } from './components/solicitar-factura/solicitar-factura.component';
import { AvisoRespuestaComponent } from './components/solicitar-factura/aviso-respuesta/aviso-respuesta.component';
import { AvisoRespuestaKoComponent } from './components/solicitar-factura/aviso-respuesta-ko/aviso-respuesta-ko.component';

const routes: Routes = [
  {path: '', component: FacturacionComponent},
  {path: 'solicitar-factura', component: SolicitarFacturaComponent},
  {path: 'solicitar-factura/respuesta', component: AvisoRespuestaComponent},
  {path: 'solicitar-factura/respuestaKo', component: AvisoRespuestaKoComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
