<div class="invoicing">
  <!-- Página de inicio -->
  <div class="container my-5">
    <div class="row">
      <div class="col-12 col-lg-6 col-xxl">
        <h1 class="greenTitle">
          {{ "invoicing.titleGreen" | translate }}
          <div class="blueTitle">{{ "invoicing.titleBlue" | translate }}</div>
        </h1>
        <p class="subtitle">
          {{ "invoicing.subtitle" | translate }}
        </p>
        <p class="warning mt-4 mb-4">
          <img src="../../../assets/img/ico-info.png" alt="" />
          {{ "invoicing.warning" | translate }}
        </p>
        <div class="disclaimer mt-3">
          {{ "invoicing.info" | translate }}
          <strong>{{ "invoicing.applePay" | translate }}</strong>
          {{ "invoicing.info1" | translate }}
          <strong>{{ "invoicing.googlePay" | translate }}</strong
          >{{ "invoicing.info2" | translate }}
          <strong>{{ "invoicing.wallet" | translate }}</strong
          >{{ "invoicing.info3" | translate }}
        </div>
        <p class="textInfo mt-4">
          <span class="asterisk">*</span> {{ "invoicing.textInfo" | translate }}
        </p>
        <!-- Formulario -->
        <form [formGroup]="invoicing">
          <div class="row mt-4">
            <div class="col mb-3">
              <div class="form-group">
                <label for="lastDigits" class="labelText"
                  ><span class="asterisk">*</span>
                  {{ "invoicing.form.lastDigits" | translate }}</label
                >
                <div class="input-group groupPass" id="show_hide_password">
                  <input
                    class="form-control numbersCard"
                    [type]="password"
                    name="lastDigits"
                    id="lastDigits"
                    formControlName="lastDigits"
                  />
                  <div class="input-group-addon">
                    <button tabindex="-1" (click)="onClick()">
                      <img
                        src="../../../assets/img/visibility_off.svg"
                        *ngIf="!show"
                        class="icon-input"
                      />
                      <img
                        src="../../../assets/img/visibility_on.svg"
                        *ngIf="show"
                      />
                    </button>
                  </div>
                </div>
                <div
                  *ngIf="checkError('lastDigits', 'required')"
                  class="infoError"
                >
                  {{ "invoicing.form.required" | translate }}
                </div>
                <div
                  *ngIf="checkError('lastDigits', 'minlength')"
                  class="infoError"
                >
                  {{ "invoicing.form.minDigits" | translate }}
                </div>
                <div
                  *ngIf="checkError('lastDigits', 'maxlength')"
                  class="infoError"
                >
                  {{ "invoicing.form.maxDigits" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm">
              <label for="amount" class="labelText"
                ><span class="asterisk">*</span>
                {{ "invoicing.form.amount" | translate }}</label
              >
              <input
                type="number"
                name="amount"
                id="amount"
                class="form-control amount"
                formControlName="amount"
              />
              <div *ngIf="checkError('amount', 'required')" class="infoError">
                {{ "invoicing.form.required" | translate }}
              </div>
              <div *ngIf="checkError('amount', 'pattern')" class="infoError">
                {{ "invoicing.form.maxDecimal" | translate }}
              </div>
            </div>
            <div class="col-12 col-sm mt-3 mt-sm-0">
              <label for="rechargeDate" class="labelText"
                ><span class="asterisk">*</span>
                {{ "invoicing.form.dateRecharge" | translate }}</label
              >
              <input
                type="date"
                name="rechargeDate"
                id="rechargeDate"
                class="form-control calendar"
                formControlName="rechargeDate"
              />
              <div
                *ngIf="checkError('rechargeDate', 'required')"
                class="infoError"
              >
                {{ "invoicing.form.required" | translate }}
              </div>
            </div>
          </div>
          <div class="row my-4">
            <div class="infoError" *ngIf="showMessage">
              {{ "invoicing.form.required1" | translate }}
            </div>
            <div class="col-12 col-sm">
              <button
                type="button"
                class="greenButton btnFullW btSpinner"
                (click)="searchTicket()"
              >
                {{ "invoicing.form.buttonSearch" | translate }}
                <div class="clearfix">
                  <span
                    class="spinner-border spinner-border-sm spinner float-end"
                    role="status"
                    *ngIf="spinnerVisible"
                  ></span>
                </div>
              </button>
            </div>
            <div class="col-12 col-sm text-center">
              <a href="{{ faqsUrl }}" target="_parent" class="buttonText">{{
                "invoicing.form.linkFaqs" | translate
              }}</a>
            </div>
          </div>
        </form>
        <!-- Fin Formulario -->
      </div>
      <div class="col-1 d-none d-sm-block"></div>
      <div class="col-12 col-lg-5 col-xxl d-none d-sm-block">
        <img src="../../../assets/img/invoicing.png" class="imgInvo" />
      </div>
    </div>
  </div>

  <div id="seccionDestino"></div>

  <!-- Bloque estado inicial -->
  <div class="initialState" *ngIf="blockDefault">
    <div class="container">
      <div class="row justify-content-center align-items-center resultado">
        <div class="col-6">
          <div class="text-center">
            <img src="../../../assets/img/car.svg" />
          </div>
          <div class="mt-3 responseInfo text-center">
            {{ "invoicing.initialState.subtitle" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Bloque Respuesta OK -->
  <div class="responseOk" *ngIf="showOK">
    <div class="container">
      <div class="row py-5 justify-content-center align-items-center resultado">
        <div class="col-sm-8">
          <div class="text-center">
            <img src="../../../assets/img/Check.png" />
          </div>
          <div class="greySubtitle mt-3 px-sm-5">
            {{ "invoicing.responseOk.titleOk" | translate }}
          </div>
          <div class="responseData my-5">
            <div class="row">
              <div class="col-sm-7">
                <div class="responseText">
                  {{ "invoicing.responseOk.responseDateRecharge" | translate }}
                </div>
                <div class="responseValue mb-3">
                  {{ dateRecharge | date : "dd/MM/yyyy" }}
                </div>
                <div class="responseText">
                  {{ "invoicing.responseOk.responseTicketNum" | translate }}
                </div>
                <div class="responseValue mb-3">{{ ticketNumber }}</div>
                <div class="responseText">
                  {{
                    "invoicing.responseOk.responseRechargeStation" | translate
                  }}
                </div>
                <div class="responseValue">{{ locationRecharge }}</div>
              </div>
              <div class="col text-sm-end align-self-end mt-3 mt-sm-0">
                <div class="responseAmount">
                  <sup><img src="../../../assets/img/currency-eur.png" /></sup
                  >{{ getWholeNumber() }}<span>,{{ getDecimalNumber() }}</span>
                </div>
                <div class="responseInfo">
                  {{ "invoicing.responseOk.responseTotalAmount" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <p class="text-center responseAction">
              {{ "invoicing.responseOk.subtitleOk" | translate }}
            </p>
          </div>
          <div class="row">
            <div class="col-sm text-end">
              <button
                type="button"
                class="greenButton btnFullW"
                (click)="downloadPDF()"
              >
                {{ "invoicing.responseOk.buttonPdf" | translate }}
                <img src="../../../assets/img/tray-arrow-down.png" />
              </button>
            </div>
            <div class="col-sm text-start">
              <button
                type="button"
                class="whiteButton btnFullW mt-3 mt-sm-0"
                [routerLink]="['/solicitar-factura']"
                [queryParams]="{
                  ticketNumber: ticketNumber,
                  sessionId: sessionId,
                  taxIdentificationNumber: taxIdentificationNumber
                }"
              >
                <img src="../../../assets/img/ico-invoice.png" />
                {{ "invoicing.responseOk.buttonInvoice" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Respuesta Respuesta KO -->
  <div class="responseKo" *ngIf="showKO">
    <div class="container py-5">
      <div class="row justify-content-center align-items-center resultado">
        <div class="col-sm-8">
          <div class="text-center">
            <img src="../../../assets/img/care.png" />
          </div>
          <div class="greySubtitle mt-3 px-sm-5">
            {{ "invoicing.responseKo.titleKo" | translate }}
          </div>
          <div class="responseData my-5">
            <div class="row">
              <div class="col subtitle text-center">
                <p>
                  {{ "invoicing.responseKo.textKo" | translate }}
                </p>
                <p>{{ "invoicing.responseKo.subtitleKo" | translate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col d-block d-sm-none">
    <img src="../../../assets/img/invoicing.png" class="img-fluid" />
  </div>
  <!-- PDF -->
  <div class="divPDF" style="overflow: hidden; height: 0" id="pdf" #pdf>
    <div class="divPDF2">
      <div><img src="../../../assets/img/logo-pdf.png" /></div>
      <div class="pdfSeparador"></div>
      <div class="row">
        <div class="col">
          <div class="pdfLabel">
            {{ "invoicing.pdf.ticketNumber" | translate }}
          </div>
          <div class="pdfValue">{{ ticketNumber }}</div>
        </div>
        <div class="col">
          <div class="pdfLabel">
            {{ "invoicing.pdf.dateTicket" | translate }}
          </div>
          <div class="pdfValue">
            {{ fechaHoraActual | date : "dd/MM/yyyy H:mm" }}
          </div>
        </div>
      </div>
      <div class="pdfSeparador"></div>
      <div class="row">
        <div class="col">
          <div class="pdfTitle mb-2">
            {{ "invoicing.pdf.card" | translate }}
          </div>
          <div class="pdfLabel">{{ "invoicing.pdf.number" | translate }}</div>
          <div class="pdfValue">{{ fourDigits }}</div>
        </div>
      </div>
      <div class="pdfSeparador"></div>
      <div class="row">
        <div class="col">
          <div class="pdfTitle mb-2">
            {{ "invoicing.pdf.recharge" | translate }}
          </div>
          <div class="pdfLabel">
            {{ "invoicing.pdf.rechargeStation" | translate }}
          </div>
          <div class="pdfValue">
            <img src="../../../assets/img/Mark.png" /> {{ locationRecharge }}
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div class="pdfLabel">
            {{ "invoicing.pdf.initalDate" | translate }}
          </div>
          <div class="pdfValue">
            {{ startDateRecharge | date : "dd/MM/yyyy H:mm:ss" }}
          </div>
        </div>
        <div class="col">
          <div class="pdfLabel">{{ "invoicing.pdf.endDate" | translate }}</div>
          <div class="pdfValue">
            {{ endDateRecharge | date : "dd/MM/yyyy H:mm:ss" }}
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div class="pdfLabel">{{ "invoicing.pdf.totalKwh" | translate }}</div>
          <div class="pdfValue">
            <img src="../../../assets/img/Lightning.png" />
            {{ totalKwh | number : "1.1-2" }} kWh
          </div>
        </div>
        <div class="col">
          <div class="pdfLabel">{{ "invoicing.pdf.duration" | translate }}</div>
          <div class="pdfValue">
            <img src="../../../assets/img/Clock.png" /> {{ durationRecharge }}
          </div>
        </div>
      </div>
      <div class="pdfSeparador"></div>
      <div class="row">
        <div class="col">
          <div class="pdfTitle mb-2">
            {{ "invoicing.pdf.provider" | translate }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="pdfLabel">{{ "invoicing.pdf.company" | translate }}</div>
          <div class="pdfValue">{{ jsonData.company }}</div>
        </div>
        <div class="col">
          <div class="pdfLabel">{{ "invoicing.pdf.cif" | translate }}</div>
          <div class="pdfValue">{{ jsonData.cif }}</div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div class="pdfLabel">{{ "invoicing.pdf.service" | translate }}</div>
          <div class="pdfValue">{{ jsonData.service }}</div>
        </div>
      </div>
      <div class="pdfSeparador"></div>
      <div class="pdfTitle mb-2">{{ "invoicing.pdf.amounts" | translate }}</div>
      <div class="row">
        <div class="col">
          <div class="pdfLabel">{{ "invoicing.pdf.subtotal" | translate }}</div>
          <div class="pdfLabel mt-2">
            {{ "invoicing.pdf.vat" | translate }} {{ taxIdentificationNumber }}%
          </div>
          <div class="pdfLabel mt-2">
            <strong>{{ "invoicing.pdf.totalAmount" | translate }}</strong>
            {{ "invoicing.pdf.totalAmount1" | translate }}
          </div>
        </div>
        <div class="col text-end">
          <div class="pdfValue">€ {{ netoAmount | number : "1.2-2" }}</div>
          <div class="pdfValue mt-2">€ {{ taxAmount | number : "1.2-2" }}</div>
          <div class="pdfValue mt-2">
            € {{ totalAmount | number : "1.2-2" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
