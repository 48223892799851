import { TranslateService } from '@ngx-translate/core';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Facturacion } from 'src/app/interfaces/facturacion.interface';
import { FacturacionService } from 'src/app/services/facturacion.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as data from 'src/assets/json/provider.json';
import { environment } from 'src/environments/environment';
import { DecimalPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from 'src/app/services/translation.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-facturacion',
  templateUrl: './facturacion.component.html',
  styleUrls: ['./facturacion.component.css'],
})
export class FacturacionComponent {
  ticketNumber: string = '';
  dateRecharge: string = '';
  locationRecharge: string = '';
  fourDigits: string = '';
  totalAmount: number = 0;
  transactionNumber: string = '';
  taxIdentificationNumber: number = 0;
  startDateRecharge: string = '';
  endDateRecharge: string = '';
  durationRecharge: string = '';
  totalKwh: number = 0;
  sessionId: string = '';
  showOK: boolean = false;
  showKO: boolean = false;
  blockDefault: boolean = true;
  spinnerVisible: boolean = false;
  fechaHoraActual: Date = new Date();
  netoAmount: number = 0;
  taxAmount: number = 0;
  taxIVA: number = 0;
  password: string = '';
  show = false;
  netoString: any;
  totalString: any;
  jsonData: any = data;
  invalidFormMessage: string = '';
  showMessage: boolean = false;
  lang: string = '';
  faqsUrl: string = '';

  @ViewChild('pdf', { static: false }) pdf!: ElementRef;

  invoicing: FormGroup;

  constructor(
    private decimalPipe: DecimalPipe,
    private facturacionService: FacturacionService,
    private el: ElementRef,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private translateService: TranslateService,
    private readonly meta: Meta
  ) {
    this.updateUrlLink();

    this.invoicing = new FormGroup({
      lastDigits: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
      ]),
      amount: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d+(?:\.\d{1,2})?$/),
      ]),
      rechargeDate: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.password = 'password';
    this.translationService.translate();
  }

  onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  async searchTicket() {
    if (this.invoicing.valid) {
      const formLastDigits = this.invoicing.get('lastDigits')?.value;
      const amount = this.invoicing.get('amount')?.value;
      const decimalAmount: any = this.decimalPipe.transform(amount, '1.2-2');
      const formAmount = decimalAmount;
      const formRechargeDate = this.invoicing.get('rechargeDate')?.value;
      const country = 'ES';

      try {
        const apiResponse = await this.getInvoicing(
          country,
          String(formRechargeDate),
          formAmount,
          String(formLastDigits)
        );

        this.dateRecharge = apiResponse.recharge_date;
        this.fourDigits = `${'XXXXXXXX'}${formLastDigits}`;
        this.ticketNumber = apiResponse.invoice_reference_id;
        this.locationRecharge = `${apiResponse.cdr_location_address}, ${apiResponse.cdr_location_city}, ${apiResponse.cdr_location_postal_code}`;
        this.totalAmount = apiResponse.amount;
        this.transactionNumber = apiResponse.uuid;
        this.taxIdentificationNumber = apiResponse.tax_percentage;
        this.startDateRecharge = apiResponse.start_date_time;
        this.endDateRecharge = apiResponse.end_date_time;
        this.totalKwh = apiResponse.total_energy;
        this.sessionId = apiResponse.session_id;
        this.blockDefault = false;
        this.showOK = true;
        this.showKO = false;
        this.scrollToElement();
      } catch (error) {
        this.handleRequestError(error);
      }

      this.calculateDuration();
      this.calculatePrices();
      this.getWholeNumber();
      this.getDecimalNumber();
    } else {
      this.showMessage = true;
    }
  }

  updateUrlLink() {
    const baseUrl = environment.baseUrl;
    const currentLanguage = this.translationService.getLang();
    if (currentLanguage === 'en') {
      this.faqsUrl = `${baseUrl}/en/faqs`;
    } else if (currentLanguage === 'pt') {
      this.faqsUrl = `${baseUrl}/pt/preguntas-frequentes`;
    } else {
      this.faqsUrl = `${baseUrl}/preguntas-frecuentes`;
    }
  }

  scrollToElement() {
    const seccionDestino =
      this.el.nativeElement.querySelector('#seccionDestino');
    if (seccionDestino) {
      seccionDestino.scrollIntoView({ behavior: 'smooth' });
    }
  }
  calculateDuration() {
    const initialDate = new Date(this.startDateRecharge);
    const endDate = new Date(this.endDateRecharge);

    const duration = endDate.getTime() - initialDate.getTime();
    const seg = Math.floor(duration / 1000);
    const min = Math.floor(seg / 60);
    const hours = Math.floor(min / 60);
    const minRest = min % 60;
    const segRest = seg % 60;

    this.durationRecharge = `${hours} hrs, ${minRest} mins, ${segRest} segs`;
  }

  calculatePrices() {
    this.taxIVA = this.taxIdentificationNumber / 100;
    this.netoAmount = this.totalAmount / (1 + this.taxIVA);
    this.taxAmount = this.totalAmount - this.netoAmount;
  }

  getWholeNumber() {
    return Math.floor(this.totalAmount);
  }

  getDecimalNumber() {
    return Number((this.totalAmount % 1).toFixed(2)) * 100;
  }

  async getInvoicing(
    country: string,
    formRechargeDate: string,
    formAmount: number,
    formLastDigits: string
  ): Promise<Facturacion> {
    try {
      this.showSpinner();
      const ticket = await this.facturacionService.getInvoicing(
        country,
        formRechargeDate,
        formAmount,
        formLastDigits
      );
      this.hideSpinner();
      return ticket;
    } catch (error) {
      this.hideSpinner();
      this.showKO = true;
      this.handleRequestError(error);
      throw error;
    }
  }

  showSpinner() {
    this.spinnerVisible = true;
    this.blockDefault = true;
  }

  hideSpinner() {
    this.spinnerVisible = false;
  }

  handleRequestError(error: any) {
    this.showOK = false;
    this.blockDefault = false;
    this.showKO = true;
    this.scrollToElement();
  }

  checkError(field: string, error: string): boolean | undefined {
    return (
      this.invoicing.get(field)?.hasError(error) &&
      this.invoicing.get(field)?.touched
    );
  }

  downloadPDF() {
    const pdfTable = this.pdf.nativeElement;
    const save = this.save;
    const viewport = this.meta.getTag('name="viewport"')?.content || '';
    const meta = this.meta;
    this.meta.removeTag('name="viewport"');
    this.meta.addTag({ name: 'viewport', content: 'width=1000' });
    html2canvas(pdfTable, {
      onclone: function (clonedDoc, clonedElement) {
        clonedElement.style.height = '100%';
      },
    })
      .then(function (canvas) {
        const img = canvas.toDataURL('image/jpg', 1);

        const doc = new jsPDF({
          orientation: 'p',
          unit: 'mm',
          format: 'a4',
        });
        doc.addImage(img, 'JPG', 20, 20, 300, 0);
        save(doc);
      })
      .then(function () {
        meta.removeTag('name="viewport"');
        meta.addTag({ name: 'viewport', content: viewport });
      });
  }

  save(doc: jsPDF) {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      const blob = doc.output('blob');
      const URL = window.URL || window.webkitURL;
      window.open(URL.createObjectURL(blob));
    } else {
      doc.save(`${new Date().toISOString()}_ticket.pdf`);
    }
  }
}
