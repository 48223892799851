import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FacturacionComponent } from './components/facturacion/facturacion.component';
import { SolicitarFacturaComponent } from './components/solicitar-factura/solicitar-factura.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FacturacionService } from './services/facturacion.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AvisoRespuestaComponent } from './components/solicitar-factura/aviso-respuesta/aviso-respuesta.component';
import { AvisoRespuestaKoComponent } from './components/solicitar-factura/aviso-respuesta-ko/aviso-respuesta-ko.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DecimalPipe } from '@angular/common';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    FacturacionComponent,
    SolicitarFacturaComponent,
    AvisoRespuestaComponent,
    AvisoRespuestaKoComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [FacturacionService, DecimalPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
